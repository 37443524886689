<script setup lang="ts">
import { type Placement, type Strategy, useFloating, size, offset, flip, shift, autoUpdate } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { ref, watch } from 'vue';

interface Props {
  placement?: Placement;
  strategy?: Strategy;
  isDropdown?: boolean;
  keepOpenOnReclicked?: boolean;
  disabled?: boolean;
  crossAxis?: boolean;
  preventFlip?: boolean;
  preventNativeAnchorClick?: boolean;
  anchorClasses?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom-start',
  strategy: 'absolute',
  isDropdown: false,
  keepOpenOnReclicked: false,
  disabled: false,
  crossAxis: undefined,
  preventFlip: true,
  preventNativeAnchorClick: false,
  anchorClasses: '',
});

const isOpened = defineModel<boolean>({ required: false, default: false });

const emit = defineEmits<{
  closed: [];
  shifted: [value?: { x: number; y: number }];
}>();
const anchor = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const container = ref<HTMLElement | null>(null);
const floatingOptions = () => {
  const middleware = [
    shift({ crossAxis: props.crossAxis }),
    props.isDropdown
      ? size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        })
      : offset(5),
  ];
  if (!props.preventFlip) {
    middleware.push(flip());
  }
  return {
    placement: props.placement,
    strategy: props.strategy,
    middleware,
    whileElementsMounted: autoUpdate,
  };
};
const { floatingStyles, middlewareData } = useFloating(anchor, floating, floatingOptions());

const variantAnchorClasses = computed(() => {
  return props.anchorClasses;
});

watch(
  () => middlewareData.value,
  (newVal) => {
    emit('shifted', newVal.shift || undefined);
  },
  { deep: true },
);

const open = () => {
  isOpened.value = true;
};

const close = () => {
  isOpened.value = false;
};

// close the popover on clicked outside
onClickOutside(container, () => {
  close();
});

const onAnchorClicked = () => {
  if (props.disabled || props.preventNativeAnchorClick) {
    return;
  }

  if (props.keepOpenOnReclicked === true) {
    isOpened.value = true;
  } else {
    isOpened.value = !isOpened.value;
  }
};

watch(
  () => isOpened.value,
  (newVal, oldVal) => {
    if (!newVal && oldVal) {
      emit('closed');
    }
  },
);
</script>

<template>
  <div ref="container">
    <div ref="anchor" :class="variantAnchorClasses" @click="onAnchorClicked">
      <slot name="anchor" :close="close" :open="open" :isOpened="isOpened" />
    </div>
    <div v-if="isOpened" ref="floating" :style="floatingStyles" class="relative z-10" @keydown.esc="close()">
      <slot name="content" :close="close" />
    </div>
  </div>
</template>
