import { SiteType, type AnalyticsProvider } from '~/types/analyticsProvider';

export const useAnalytics = () => {
  const providers = ref<AnalyticsProvider[]>([]);
  const nuxtApp = useNuxtApp();
  const { user, globalStudentRole, isGhostStudent } = useAuth();
  const configStore = useConfigStore();
  const { isManageDomain } = useDomainConfig();

  const isYear13User = computed(() => {
    if (!user.value) {
      return false;
    }

    if (!user.value.primaryEmail) {
      return false;
    }

    const lowercaseEmail = user.value.primaryEmail.toLowerCase();

    if (lowercaseEmail.endsWith('@year13.com.au') || lowercaseEmail.endsWith('@careertools.com.au')) {
      return true;
    }

    return false;
  });

  const sanitizeData = (data: Record<string, unknown>) => {
    return Object.keys(data).reduce((carry: Record<string, string>, key) => {
      if (data[key] === null || data[key] === undefined) {
        carry[key] = '';
        return carry;
      }

      if (typeof data[key] === 'string') {
        carry[key] = data[key];
        return carry;
      }

      if (typeof data[key] === 'boolean' || typeof data[key] === 'number') {
        carry[key] = data[key].toString();
        return carry;
      }

      return carry;
    }, {});
  };

  const getBaseEventData = () => {
    const siteType = isManageDomain ? SiteType.MANAGE : SiteType.PLAN;
    return {
      cluster: configStore.cluster?.name || 'unknown',
      pageName: nuxtApp._route.name,
      product: siteType,
      ...(user.value
        ? {
            userId: user.value?.id,
            classYear: siteType === SiteType.PLAN ? globalStudentRole.value?.classYear : null,
            isYear13User: isYear13User.value,
            isGhostUser: siteType === SiteType.PLAN ? isGhostStudent.value : false,
            isManageReferred: siteType === 'plan' ? !!useCrossSitesConfig().isManageReferred.value : undefined,
          }
        : {}),
    };
  };

  const track = (eventName: string, specificEventData: object = {}) => {
    if (!import.meta.client) {
      return;
    }

    const eventData = sanitizeData({ ...getBaseEventData(), ...specificEventData });

    providers.value.forEach((provider) => {
      if (provider.getSubscribedEvents().includes(eventName)) {
        try {
          provider.track(eventName, eventData);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    });
  };

  const identify = (guid: string | undefined) => {
    if (!import.meta.client) {
      return;
    }

    providers.value.forEach((provider) => {
      try {
        provider.identify(guid);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  };

  const registerProvider = async (provider: AnalyticsProvider) => {
    await provider.init();
    providers.value.push(provider);
  };

  return { track, identify, registerProvider };
};
