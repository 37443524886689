<script lang="ts" setup></script>

<template>
  <div class="career-coach px-4">
    <div class="container flex flex-col md:flex-row gap-8 pt-16 md:pt-24">
      <div class="md:w-1/3 flex flex-col gap-8 md:pt-0 md:-mt-4 xl:pt-32 text-center lg:text-left">
        <UIHeading
          :htmlText="$t('general.landing.careerCoachByYourSide')"
          class="title mt-9 w-10/12 mx-auto lg:mx-0 font-thin"
          size="h2"
          styleSize="h2"
        />
        <p class="subtitle">
          {{ $t('general.landing.poweredByAi') }}
          <UIAssetIcon class="shrink-0" path="sparkle" size="md" />
        </p>
        <div class="cta-btn">
          <UIButton :text="$t('general.landing.startTalking')" customClasses="w-fit mx-auto md:mx-0 " to="/onboarding" />
        </div>
      </div>
      <div class="image md:w-2/3 text-center">
        <NuxtImg alt="" class="w-full mt-2 md:-mt-20" preload role="presentation" src="/images/landing/coach.webp" />
      </div>
    </div>
  </div>
</template>
