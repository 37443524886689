<script lang="ts" setup>
const { logout, hasAuthedCookie } = useAuth();

const openModal = ref<boolean>(false);
const timeout = ref<NodeJS.Timeout | undefined>(undefined);
watch(hasAuthedCookie, (newVal, oldVal) => {
  clearTimeout(timeout.value);
  if (!newVal && oldVal) {
    timeout.value = setTimeout(() => {
      openModal.value = true;
    }, 1000);
  }
});

const relogin = () => {
  void logout();
};
</script>

<template>
  <div>
    <UIModal v-if="openModal" hideCloseButton data-testid="sessionUnAuthedModal">
      <p class="my-5">{{ $t('general.sessionTimeout.unauthed') }}</p>
      <div class="flex justify-end">
        <UIButton variant="primary" :text="$t('general.sessionTimeout.relogin')" @click="relogin()" />
      </div>
    </UIModal>
  </div>
</template>
