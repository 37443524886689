export const useToDataTestId = () => {
  const toDataTestId = (val: string): string => {
    if (val) {
      return val.toLowerCase().replaceAll(' ', '-').replaceAll('.', '-');
    }

    return '';
  };

  return { toDataTestId };
};
