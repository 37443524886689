<script lang="ts" setup>
interface Props {
  variant?: 'default' | 'surface';
  shape?: 'default' | 'chat';
  shadow?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  padding?: 'none' | 'md'
  isButton?: boolean;
  customClasses?: string;
  overflow?: 'visible' | 'hidden';
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  shape: 'default',
  shadow: 'md',
  isButton: false,
  customClasses: '',
  padding: 'md',
  overflow: 'visible',
});

const cardType = computed<'button' | 'div'>(() => (props.isButton ? 'button' : 'div'));

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        variant: {
          default: 'border border-grey-100 bg-white',
          surface: 'bg-opacity-80 backdrop-blur-sm drop-shadow-lg bg-white',
        },
        overflow: {
          visible: 'overflow-visible',
          hidden: 'overflow-hidden'
        },
        padding: {
          none: 'px-0 py-0',
          md: 'py-6 px-4'
        },
        shape: {
          default: 'rounded-2xl',
          chat: 'rounded-tl-none rounded-tr-3xl rounded-br-3xl rounded-bl-3xl',
        },
        shadow: {
          none: '',
          md: 'shadow-md',
          lg: 'shadow-lg',
          sm: 'shadow-sm',
          xs: 'shadow-xs',
        },
      },
    })({
      variant: props.variant,
      shape: props.shape,
      shadow: props.shadow,
      overflow: props.overflow,
      padding: props.padding,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <component :is="cardType" :class="variantClasses">
    <div class="w-full">
      <slot />
    </div>
  </component>
</template>
