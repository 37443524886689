<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed, resolveComponent } from 'vue';

interface Props {
  to?: string | object | null;
  variant?: 'primary' | 'outlined' | 'no-style' | 'danger' | 'secondary' | 'success' | 'link';
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  disabled?: boolean;
  customClasses?: string;
  iconPath?: string | null;
  iconPosition?: 'left' | 'right';
  text?: string;
  textVisible?: boolean | null;
  loading?: boolean;
  isExternalLink?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  to: null,
  variant: 'primary',
  size: 'md',
  type: 'button',
  disabled: false,
  iconPath: null,
  iconPosition: 'left',
  customClasses: '',
  textVisible: true,
  text: '',
  loading: false,
  isExternalLink: false,
});

const emit = defineEmits<{
  click: [e: MouseEvent];
}>();

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('inline-block tracking-wider duration-75 delay-75 rounded-lg ease text-center', {
      variants: {
        variant: {
          primary: 'text-white font-bold bg-secondary-500 border-secondary-500 border-2 w-full sm:w-[initial]',
          'no-style': 'font-semibold',
          outlined: 'font-bold border-2 text-secondary-500 w-full sm:w-[initial]',
          danger: 'font-bold text-white bg-red-500 border-red-500 border-2',
          secondary: 'font-bold',
          success: 'text-white font-bold bg-green-500 border-green-500 border-2',
          link: 'font-semibold text-secondary-500'
        },
        size: {
          lg: 'py-4 px-8 text-base',
          md: 'py-3 px-6 text-base',
          sm: 'py-2 px-4 text-sm',
          xs: 'py-1.5 px-3 text-sm',
          '2xs': 'py-1 px-2 text-xs',
        },
        disabled: {
          true: 'opacity-50 cursor-not-allowed border-opacity-50',
        },
        loading: {
          true: 'opacity-60 text-opacity-0 relative',
        },
      },
      compoundVariants: [
        { variant: 'primary', disabled: true, class: 'bg-secondary-200 border-secondary-200' },
        { variant: 'outlined', disabled: true, class: 'text-secondary-200 border-secondary-200' },
        { variant: 'danger', disabled: true, class: 'bg-red-200 border-red-200' },
        { variant: 'link', disabled: true, class: 'text-secondary-200' },
        { variant: 'primary', disabled: false, class: 'hover:bg-secondary-600' },
        { variant: 'danger', disabled: false, class: 'hover:bg-red-600 hover:border-red-600' },
        { variant: 'link', disabled: false, class: 'hover:text-secondary-600' },
        { variant: 'no-style', class: 'p-0' },
      ],
    })({
      size: props.size,
      variant: props.variant,
      disabled: props.disabled,
      loading: props.loading,
    }),
    props.customClasses,
  );
});

const loadingVariantClasses = computed(() => {
  return twMerge(
    cva('', {
      variants: {
        variant: {
          primary: 'text-white',
          outlined: 'text-primary-500',
          secondary: 'text-white',
          danger: 'text-white',
          success: 'text-white',
          'no-style': 'text-primary-500',
          link: 'text-primary-500'
        },
      },
    })({
      variant: props.variant,
    }),
  );
});

const nuxtLink = resolveComponent('NuxtLink');
const linkRel = computed(() => (props.isExternalLink ? 'noopener noreferrer nofollow' : undefined));

const onClick = (event: MouseEvent) => {
  if (props.disabled || props.loading) {
    return;
  }

  emit('click', event);
};

const toHref = computed(() => (props.disabled ? undefined : props.to));
</script>

<template>
  <component
    :is="to ? nuxtLink : 'button'"
    :aria-disabled="disabled || loading"
    :aria-label="text"
    :class="variantClasses"
    :external="to ? isExternalLink : undefined"
    :rel="linkRel"
    :target="isExternalLink ? '_blank' : undefined"
    :to="toHref"
    :type="to ? undefined : type"
    @click="onClick"
  >
    <div class="flex items-center justify-center gap-2">
      <UIAssetIcon
        v-if="iconPath"
        :class="{ 'order-1': iconPosition === 'left', 'order-2': iconPosition === 'right' }"
        :path="iconPath"
        :size="size"
      />
      <template v-if="text && textVisible">
        <span :class="{ 'order-2': iconPosition === 'left', 'order-1': iconPosition === 'right' }">{{ text }}</span>
      </template>
      <div v-if="loading" class="absolute flex items-center justify-center">
        <UILoader :class="loadingVariantClasses" :size="size" variant="basic" />
      </div>
    </div>
  </component>
</template>
