/* eslint-disable no-console */
import type { RuntimeConfig } from 'nuxt/schema';
import type { FeatureFlagsProvider, UserContext, Variant } from '~/types/featureFlagsProvider';
import { Unleash, UnleashEvents, type Context } from 'unleash-client';

export default class ServerSideProvider implements FeatureFlagsProvider {
  private client: Unleash;

  constructor(runtimeConfig: RuntimeConfig) {
    this.client = new Unleash({
      url: runtimeConfig.public.unleashFlagsUrl,
      appName: runtimeConfig.public.appName,
      customHeaders: { Authorization: runtimeConfig.unleashFlagsServerToken },
      refreshInterval: 30,
      disableAutoStart: true,
    });
  }

  async init(): Promise<void> {
    // throw new Error('TESTING UNLEASH FAILURE ON ONLY SERVER');
    return await new Promise((resolve, reject) => {
      this.client.on(UnleashEvents.Synchronized, () => {
        console.log('unleash:on-synchronized', 'Unleash server client synchronized');
        resolve();
      });
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.client.on(UnleashEvents.Error, async (error) => {
        console.error('unleash:on-error', error);
        await this.client.destroyWithFlush();
        reject(error);
      });
      void this.client.start();
    });
  }

  async getFlag(flagName: string, userContext: UserContext): Promise<boolean> {
    // <Context> type cast makes unleash-client happy
    return await Promise.resolve(this.client.isEnabled(flagName, <Context>userContext));
  }

  async getVariant(flagName: string, userContext: UserContext): Promise<Variant> {
    // <Context> type cast makes unleash-client happy
    return await Promise.resolve(this.client.getVariant(flagName, <Context>userContext));
  }
}
