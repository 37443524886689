<script lang="ts" setup>
interface Props {
  path: string;
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
}
const props = withDefaults(defineProps<Props>(), {
  size: 'md',
});

const iconSizes = {
  '2xs': 'h-2 w-2',
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-6 w-6',
  lg: 'h-8 w-8',
  xl: 'h-10 w-10',
  '2xl': 'h-12 w-12',
  '3xl': 'h-24 w-24',
};

const assetPath = computed(() => {
  return `icons/${props.path}.svg`;
});
</script>

<template>
  <UIAsset :path="assetPath" :class="[iconSizes[size]]" />
</template>
