export const EVENTS = {
  // GENERIC EVENTS
  SCHOOLS_LANDING_PAGE_VIEWED: 'phoenix_landing_page_viewed',
  LANDING_PAGE_VIEWED: 'phoenix_landing_page_viewed',
  LOGIN_PAGE_VIEWED: 'phoenix_login_page_viewed',
  LOGIN_EMAIL_SUBMITTED: 'phoenix_login_email_submitted',
  LOGIN_OTP_SUBMITTED: 'phoenix_login_otp_submitted',
  LOGIN_OTP_RESENT: 'phoenix_login_otp_resent',
  ACCESSIBILITY_SETTINGS_UPDATED: 'phoenix_accessibility_settings_updated',
  ACCOUNT_REGISTER_NOT_AVAILABLE: 'phoenix_account_register_not_available',
  ACCOUNT_REGISTER_COMPLETED: 'phoenix_account_register_completed',
  ACTIVITY_VIEWED: 'phoenix_activity_viewed',
  ACTIVITY_SKIPPED: 'phoenix_activity_skipped',
  ACTIVITY_COMPLETED: 'phoenix_activity_completed',
  ACTIVITY_RESPONSE_SAVED: 'phoenix_activity_response_saved',
  CVB_VIEWED: 'phoenix_cvb_viewed',
  CVB_ACTIVITY_NAVIGATION: 'phoenix_cvb_activity_navigation',
  WALLET_VIEWED: 'phoenix_wallet_viewed',
  WALLET_SAVED: 'phoenix_wallet_saved',
  ACCOUNT_DETAILS_UPDATED: 'phoenix_account_details_updated',
  ACCOUNT_SCHOOLS_UPDATE: 'phoenix_account_schools_update',
  ACCOUNT_DELETED: 'phoenix_account_deleted',
  FEEDBACK_SUBMITTED: 'phoenix_feedback',
  FEATURE_FLAG_IMPRESSION: 'phoenix_feature_flag_impression',

  // ONBOARDING
  PREPLAN_USER_SUMMARY_BUTTON_CLICKED: 'phoenix_preplan_user_summary_button_clicked',
  ANIMATIONS_OFF: 'phoenix_preplan_preferences_animations_toggled_off',
  // JOB EXPLORE
  OCCUPATION_SEARCH_TRIGGERED: 'phoenix_occupation_search_search_triggered',
  OCCUPATION_SEARCH_COMPLETED: 'phoenix_occupation_search_search_completed',
  OCCUPATION_SEE_MORE_CLICKED: 'phoenix_occupation_search_see_more',
  OCCUPATION_JOB_ADDED_TO_CVB: 'phoenix_occupation_search_job_added_to_cvb',
  OCCUPATION_LEARN_MORE_CLICKED: 'phoenix_occupation_search_learn_more',
  OCCUPATION_JOB_REMOVED_CVB: 'phoenix_occupation_search_job_removed_from_cvb',

  // INSTITUTION EXPLORE
  INSTITUTION_SEARCH_TRIGGERED: 'phoenix_institution_search_search_triggered',
  INSTITUTION_SEARCH_COMPLETED: 'phoenix_institution_search_search_completed',
  INSTITUTION_SEE_MORE_CLICKED: 'phoenix_institution_search_see_more',
  INSTITUTION_SEARCH_INSTITUTION_CLICKED: 'phoenix_institution_search_institution_clicked',
  INSTITUTION_ADDED_TO_CVB: 'phoenix_institution_search_institution_added_to_cvb',
  INSTITUTION_SEARCH_INSTITUTION_IGNORED: 'phoenix_institution_search_institution_ignored',
  INSTITUTION_LEARN_MORE_CLICKED: 'phoenix_institution_search_learn_more',
  INSTITUTION_REMOVED_CVB: 'phoenix_institution_search_institution_removed_from_cvb',

  // EDUCATION PATHWAYS
  EDUCATION_PATHWAY_CLICKED: 'phoenix_education_pathways_pathway_clicked',
  EDUCATION_PATHWAY_IGNORED: 'phoenix_education_pathways_pathway_ignored',
  EDUCATION_PATHWAY_ADDED_CVB: 'phoenix_activity_education_pathway_added_to_cvb',
  EDUCATION_PATHWAY_REMOVED_CVB: 'phoenix_activity_education_pathway_removed_from_cvb',
};
