<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed, onMounted, ref, watch } from 'vue';

interface Props {
  to?: string | null;
  isOpen: boolean;
  iconPath: string;
  label: string;
  activeRoutes: string[];
  onClick?: () => void | Promise<void>;
  badgeCount?: number;
}

const props = withDefaults(defineProps<Props>(), {
  to: null,
  onClick: undefined,
  badgeCount: undefined,
});
const route = useRoute();
const { toDataTestId } = useToDataTestId();
const isLabelVisible = ref<boolean>(true);

const isActive = computed<boolean>(() => {
  const { name } = route;

  if (typeof name === 'string') {
    return props.activeRoutes.includes(name);
  }

  return false;
});

const component = computed<string | object>(() => {
  if (props.to) {
    return defineNuxtLink({});
  }

  return 'button';
});

watch(
  () => props.isOpen,
  (val) => {
    if (val) {
      setTimeout(() => {
        isLabelVisible.value = true;
      }, 150);
    } else {
      isLabelVisible.value = false;
    }
  },
);

const labelClasses = computed<string>(() => {
  return twMerge(
    cva('transition-all delay-700', {
      variants: {
        isLabelVisible: {
          true: 'opacity-1',
          false: 'opacity-0',
        },
      },
    })({
      isLabelVisible: isLabelVisible.value,
    }),
  );
});

const classes = computed<string>(() => {
  return twMerge(
    cva('flex items-center px-5 w-full transition-all duration-300', {
      variants: {
        isOpen: {
          true: 'h-[72px] gap-2 transform translate-x-0',
          false: 'h-[72px] transform translate-x-1',
        },
      },
    })({
      isOpen: props.isOpen,
    }),
  );
});

const activeClass = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        isActive: {
          true: 'text-white bg-primary-600',
          false: 'text-white hover:bg-primary-800',
        },
      },
    })({
      isActive: isActive.value,
    }),
  );
});

const iconClasses = computed<string>(() =>
  twMerge(
    cva('shrink-0 text-white', {
      variants: {
        isActive: {
          true: 'fill-white text-primary-500',
          false: 'fill-primary-500',
        },
      },
    })({
      isActive: isActive.value,
    }),
  ),
);

onMounted(() => {
  isLabelVisible.value = props.isOpen;
});

const clickHandler = async () => {
  if (component.value === 'button' && props.onClick) {
    await Promise.resolve(props.onClick());
  }
};
</script>

<template>
  <li :class="activeClass">
    <component :is="component" :aria-label="label" class="" :class="classes" :to="to" @click="clickHandler">
      <div class="relative flex">
        <UIAssetIcon :path="iconPath" size="md" :class="iconClasses" />
        <div
          v-if="badgeCount"
          class="bg-red-500 text-white absolute -top-1/3 -right-1/3 rounded-full p-1 w-[20px] h-[20px] flex items-center justify-center"
          :data-testid="`badge-count-${toDataTestId(label)}`"
        >
          <span class="text-[9.5px]">{{ badgeCount }}</span>
        </div>
      </div>
      <p v-show="isOpen" :class="labelClasses">{{ label }}</p>
    </component>
  </li>
</template>
