import { type Client, createClient, fetchExchange, type SSRData, ssrExchange, mapExchange, subscriptionExchange } from '@urql/core';
import { cacheExchange } from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
import { defineNuxtPlugin } from '#app';
import schema from '@/graphql/generated/introspection.json';
import { RespondStudentRoleRequestStatus, type BulkRespondStudentRoleRequestsInput, type SchoolMutations } from '~/graphql/generated/graphql';

const CACHE_KEY = '__URQL_DATA__';

const GQL_OP_STREAMING_SET = new Set<number>();

export default defineNuxtPlugin({
  name: 'urql',
  dependsOn: [],
  setup: (nuxtApp) => {
    const ssrHeaders = useRequestHeaders(['cookie', 'user-agent']);

    const { apiUrl, siteDomain } = useDomainConfig();
    const { forwardSubscriptionToPusher } = useSubscriptionExchange();

    const _ssrExchange = ssrExchange({
      isClient: import.meta.client,
    });

    // ssr data in nuxt state
    const ssrData = useState<SSRData>(CACHE_KEY);

    // restore SSR state from nuxt payload
    if (import.meta.client) {
      _ssrExchange.restoreData(ssrData.value);
    }

    // when app has rendered in server, send SSR state to client
    if (import.meta.server) {
      nuxtApp.hook('app:rendered', () => {
        ssrData.value = _ssrExchange.extractData();
      });
    }

    const urqlClient = createClient({
      url: `${apiUrl}/graphql`,
      exchanges: [
        devtoolsExchange,
        cacheExchange({
          schema,
          keys: {
            CoreQueries: () => null,
            SiteQueries: () => null,
            OrgQueries: () => null,
            UserQueries: () => null,
            SchoolQueries: () => null,
            WalletQueries: () => null,
            PublicSiteConfig: () => null,
            ClusterTheme: () => null,
            ClusterLogos: () => null,
            CareerPassValueProposition: () => null,
            AISelectOptions: () => null,
            InterestOptions: () => null,
            Interests: () => null,
            PaginatorInfo: () => null,
            StudentRolePaginator: () => null,
            StudentRoleRequestPaginator: () => null,
            ActivityConfig: () => null,
            SystemConfig: () => null,
            AutoLoadConfig: () => null,
            InterestSelectionConfig: () => null,
            ValuePropositionConfig: () => null,
            OccupationSearchConfig: () => null,
            OccupationSearchConfigData: () => null,
            OccupationSearchInitialValue: () => null,
            OccupationSearchInitialValueFields: () => null,
            OccupationTags: () => null,
            OccupationCareerConsideration: () => null,
            OccupationMeta: () => null,
            OccupationSearchResults: () => null,
            EducationPathwaysInitialValue: () => null,
            EducationPathwaysInitialValueFields: () => null,
            EducationPathwaysConfig: () => null,
            EducationPathwaysConfigData: () => null,
            AcademicPlanInitialValue: () => null,
            AcademicPlanData: () => null,
            AcademicPlanConfig: () => null,
            AcademicPlanCourseData: () => null,
            AcademicPlanConfigData: () => null,
            UserWallet: () => null,
            WalletConfig: () => null,
            WalletCategoryConfig: () => null,
            WalletSelectOption: () => null,
            AddressDetails: () => null,
            AchievementSummary: () => null,
            Achievement: () => null,
            BioDetails: () => null,
            WorkExperienceSummary: () => null,
            Evidence: () => null,
            Education: () => null,
            EducationDetails: () => null,
            EducationSummary: () => null,
            InstitutionDetails: () => null,
            TranscriptReport: () => null,
            WorkExperience: () => null,
            MyDetails: () => null,
            CareerVisionBoardInitialValue: () => null,
            CareerVisionBoardConfigData: () => null,
            CareerVisionBoardConfig: () => null,
            CareerVisionBoardReviewInitialValue: () => null,
            CareerVisionBoardReviewConfig: () => null,
            DefaultInitialValueFields: () => null,
            ValuePropositionInitialValue: () => null,
            InterestSelectionInitialValue: () => null,
            PreplanAIInitialValue: () => null,
            PreplanSummaryInitialValue: () => null,
            PreplanAIInitialValueFields: () => null,
            PreplanAIQuestion: () => null,
            PreplanAIConfig: () => null,
            PreplanAIConfigData: () => null,
            YourAssistantConfig: () => null,
            YourAssistantInitialValue: () => null,
            YourAssistantConfigData: () => null,
            ValuePropositionsConfigData: () => null,
            ValueProposition: () => null,
            InterestSelectionInitialValueFields: () => null,
            InterestSelectionConfigData: () => null,
            CheckStudentRoleInvitePayload: () => null,
            Interest: () => null,
            FieldOfInterest: () => null,
            FeedbackResponse: () => null,
            PreplanSummaryConfig: () => null,
            PreplanSummaryConfigData: () => null,
            AboutYouTrait: () => null,
            FillCareerProfileInitialValue: () => null,
            FillCareerProfileConfig: () => null,
            PreplanPreferencesInitialValue: () => null,
            PreplanPreferencesInitialValueFields: () => null,
            PreplanPreferencesConfig: () => null,
            PreplanPreferencesConfigData: () => null,
            UserProfileSummary: () => null,
            UserInterests: () => null,
            OnetSkill: () => null,
            JobsSaved: () => null,
            UserSkills: () => null,
            FieldOfInterestValue: () => null,
            PathwayTypePreference: () => null,
            PrePlanSummary: () => null,
            PotentialJob: () => null,
            RaisecProfile: () => null,
            SchoolPaginator: () => null,
            StudentRoleInvitePaginator: () => null,
            InstitutionSearchInitialValue: () => null,
            InstitutionSearchInitialValueFields: () => null,
            InstitutionSearchConfig: () => null,
            InstitutionSearchConfigData: () => null,
            InstitutionSearchResults: () => null,
            InstitutionMeta: () => null,
            InstituteDetails: () => null,
            InstitutionSites: () => null,
            InstitutionCampusDetail: () => null,
            InstitutionsSaved: () => null,
            ClassYearOption: () => null,
            AcceptedFileType: () => null,
            PotentialCareer: () => null,
            Career: () => null,
          },
          updates: {
            SchoolMutations: {
              approveStudentRoleRequest(_result, args, cache, _info) {
                cache.invalidate('StudentRoleRequest');
                cache.invalidate('StudentRolePaginator');
              },
              declineStudentRoleRequest(_result, args, cache, _info) {
                cache.invalidate('StudentRoleRequest');
              },
              inviteStudents(_result, args, cache, _info) {
                cache.invalidate('StudentRoleInvitePaginator');
              },
              deleteStudentRoleInvite(_result, args, cache, _info) {
                cache.invalidate('StudentRoleInvite');
              },
              bulkRespondStudentRoleRequests(_result: SchoolMutations, args: BulkRespondStudentRoleRequestsInput, cache, _info) {
                if (_result.bulkRespondStudentRoleRequests.success === true) {
                  cache.invalidate('StudentRoleRequest');

                  if (args.status === RespondStudentRoleRequestStatus.Approved) {
                    cache.invalidate('StudentRolePaginator');
                  }
                }
              },
              bulkResendStudentRoleInvites(_result: SchoolMutations, args, cache, _info) {
                if (_result.bulkResendStudentRoleInvites.success === true) {
                  cache.invalidate('StudentRoleInvite');
                }
              },
              bulkDeleteStudentRoleInvites(_result: SchoolMutations, args, cache, _info) {
                if (_result.bulkDeleteStudentRoleInvites.success === true) {
                  cache.invalidate('StudentRoleInvite');
                }
              },
              bulkDeleteStudentRoles(_result: SchoolMutations, args, cache, _info) {
                if (_result.bulkDeleteStudentRoles.success === true) {
                  cache.invalidate('StudentRolePaginator');
                }
              },
            },
            AuthMutations: {
              logout(_result, args, cache, _info) {
                cache.invalidate('CoreQueries');
                cache.invalidate('CoreMutations');
              },
            },
            UserMutations: {
              addStudentRoleToTeacher(_result, args, cache, _info) {
                cache.invalidate('StudentRolePaginator');
              },
            },
            Subscription: {
              studentRoleRequestCreated(_result, args, cache, _info) {
                // clean paginator cache when event is being captured outside manage-student-invites
                // manage-student-invites has a new-data notification banner
                if (nuxtApp._route.name !== 'manage-student-invites') {
                  cache.invalidate('StudentRoleRequestPaginator');
                }
              },
            },
          },
        }),
        _ssrExchange,
        mapExchange({
          onResult: (result) => {
            if (import.meta.server) {
              return result;
            }

            const { reset, refreshExpiry } = useAuthStore();

            // Streamed responses do not bump Laravel session cookie expiry, only the initial request, such as with a subscription, so we avoid bumping the expiry tracking on the FE
            if (!result.hasNext) {
              refreshExpiry();
              GQL_OP_STREAMING_SET.delete(result.operation.key);
            } else if (!GQL_OP_STREAMING_SET.has(result.operation.key)) {
              refreshExpiry();
              GQL_OP_STREAMING_SET.add(result.operation.key);
            }

            if (!result.error) {
              return result;
            }

            const unauthenticatedError = findGraphqlErrorWithCode(result.error, 'UNAUTHENTICATED');

            if (unauthenticatedError) {
              showError({ statusCode: 401, statusMessage: <string>unauthenticatedError.extensions.clientMessage });
              reset();
              return result;
            }

            const unauthorizedError = findGraphqlErrorWithCode(result.error, 'UNAUTHORIZED');
            if (unauthorizedError) {
              showError({ statusCode: 403, statusMessage: <string>unauthorizedError.extensions.clientMessage });
              return result;
            }

            return result;
          },
        }),
        fetchExchange,
        subscriptionExchange({ forwardSubscription: forwardSubscriptionToPusher }),
      ],
      fetchOptions: {
        headers: {
          ...(ssrHeaders && ssrHeaders),
          origin: siteDomain,
        },
      },
    });

    nuxtApp.vueApp.provide('$urql', ref(urqlClient));
    nuxtApp.provide('urql', urqlClient);

    return { provide: { urqlClient } };
  },
});

declare module '#app' {
  interface NuxtApp {
    $urqlClient: Client;
  }
}
