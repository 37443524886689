import { default as account_45deletedopIbesvIjkMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/account-deleted.vue?macro=true";
import { default as indexYIKW2YrVXDMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/account/index.vue?macro=true";
import { default as indexgBG918QNrNMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/dashboard/index.vue?macro=true";
import { default as index41VcUzQbA5Meta } from "/var/www/apps/phoenix/nuxt/pages/manage/index.vue?macro=true";
import { default as loginjAMtrbK4tSMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/login.vue?macro=true";
import { default as indexeFC2ShwzIEMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/student-invites/index.vue?macro=true";
import { default as inviteHsRz0Z8GHsMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/student-invites/invite.vue?macro=true";
import { default as indexZ3RjwE1vaVMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/students/[student_role_id]/index.vue?macro=true";
import { default as indexeKq6vjqrLLMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/students/[student_role_id]/portfolio/index.vue?macro=true";
import { default as terms_45conditionzMLsfeLkZEMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/terms-condition.vue?macro=true";
import { default as indexqzz8hvDTIDMeta } from "/var/www/apps/phoenix/nuxt/pages/manage/verify-email/index.vue?macro=true";
import { default as indexoFM8YGKnngMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/academic-plan/index.vue?macro=true";
import { default as accept_45inviteAaUVLxn8QGMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/accept-invite.vue?macro=true";
import { default as account_45deletedREgj68G9G9Meta } from "/var/www/apps/phoenix/nuxt/pages/plan/account-deleted.vue?macro=true";
import { default as indexkUKuayMFycMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/account/index.vue?macro=true";
import { default as dashboard_45splitdCLfHcOhkDMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/dashboard-split.vue?macro=true";
import { default as dashboardKRSEPmtBCTMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/dashboard.vue?macro=true";
import { default as indexIvPOL8Tvn6Meta } from "/var/www/apps/phoenix/nuxt/pages/plan/education-pathways/index.vue?macro=true";
import { default as error_45test_45client0GGQgR3mOMMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/error-test-client.vue?macro=true";
import { default as error_45test_45ssrIDHWOnG8UzMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/error-test-ssr.vue?macro=true";
import { default as flag_45testfDaPJrS559Meta } from "/var/www/apps/phoenix/nuxt/pages/plan/flag-test.vue?macro=true";
import { default as indexQUfINGJCArMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/index.vue?macro=true";
import { default as indexuJbmiQGgvRMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/institution-explore/index.vue?macro=true";
import { default as _91id_930J3Ikv00U9Meta } from "/var/www/apps/phoenix/nuxt/pages/plan/job-explore/[id].vue?macro=true";
import { default as indexavLYR5bIFSMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/job-explore/index.vue?macro=true";
import { default as loginxo3gHlClREMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/login.vue?macro=true";
import { default as indexyKxozoZ6fMMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/onboarding/index.vue?macro=true";
import { default as indexWBOH5Tsn7RMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/portfolio/index.vue?macro=true";
import { default as indexvlxvwMnwWlMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/summary/index.vue?macro=true";
import { default as terms_45condition8ZgsslqJMOMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/terms-condition.vue?macro=true";
import { default as indexNSEtYbFI5kMeta } from "/var/www/apps/phoenix/nuxt/pages/plan/verify-email/index.vue?macro=true";
import { default as component_45stubARdVsNdG3GMeta } from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_nfp5cyka32hbdbal6zysdhgkxy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubARdVsNdG3G } from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_nfp5cyka32hbdbal6zysdhgkxy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "manage-account-deleted",
    path: "/manage/account-deleted",
    meta: account_45deletedopIbesvIjkMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/account-deleted.vue")
  },
  {
    name: "manage-account",
    path: "/manage/account",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/account/index.vue")
  },
  {
    name: "manage-dashboard",
    path: "/manage/dashboard",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/dashboard/index.vue")
  },
  {
    name: "manage-index",
    path: "/manage",
    meta: index41VcUzQbA5Meta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/index.vue")
  },
  {
    name: "manage-login",
    path: "/manage/login",
    meta: loginjAMtrbK4tSMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/login.vue")
  },
  {
    name: "manage-student-invites",
    path: "/manage/student-invites",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/student-invites/index.vue")
  },
  {
    name: "manage-student-invites-invite",
    path: "/manage/student-invites/invite",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/student-invites/invite.vue")
  },
  {
    name: "manage-students-student_role_id",
    path: "/manage/students/:student_role_id()",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/students/[student_role_id]/index.vue")
  },
  {
    name: "manage-students-student_role_id-portfolio",
    path: "/manage/students/:student_role_id()/portfolio",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/students/[student_role_id]/portfolio/index.vue")
  },
  {
    name: "manage-terms-condition",
    path: "/manage/terms-condition",
    meta: terms_45conditionzMLsfeLkZEMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/terms-condition.vue")
  },
  {
    name: "manage-verify-email",
    path: "/manage/verify-email",
    meta: indexqzz8hvDTIDMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/manage/verify-email/index.vue")
  },
  {
    name: "plan-academic-plan",
    path: "/plan/academic-plan",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/academic-plan/index.vue")
  },
  {
    name: "plan-accept-invite",
    path: "/plan/accept-invite",
    meta: accept_45inviteAaUVLxn8QGMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/accept-invite.vue")
  },
  {
    name: "plan-account-deleted",
    path: "/plan/account-deleted",
    meta: account_45deletedREgj68G9G9Meta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/account-deleted.vue")
  },
  {
    name: "plan-account",
    path: "/plan/account",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/account/index.vue")
  },
  {
    name: "plan-dashboard-split",
    path: "/plan/dashboard-split",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/dashboard-split.vue")
  },
  {
    name: "plan-dashboard",
    path: "/plan/dashboard",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/dashboard.vue")
  },
  {
    name: "plan-education-pathways",
    path: "/plan/education-pathways",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/education-pathways/index.vue")
  },
  {
    name: "plan-error-test-client",
    path: "/plan/error-test-client",
    meta: error_45test_45client0GGQgR3mOMMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/error-test-client.vue")
  },
  {
    name: "plan-error-test-ssr",
    path: "/plan/error-test-ssr",
    meta: error_45test_45ssrIDHWOnG8UzMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/error-test-ssr.vue")
  },
  {
    name: "plan-flag-test",
    path: "/plan/flag-test",
    meta: flag_45testfDaPJrS559Meta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/flag-test.vue")
  },
  {
    name: "plan-index",
    path: "/plan",
    meta: indexQUfINGJCArMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/index.vue")
  },
  {
    name: "plan-institution-explore",
    path: "/plan/institution-explore",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/institution-explore/index.vue")
  },
  {
    name: "plan-job-explore-id",
    path: "/plan/job-explore/:id()",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/job-explore/[id].vue")
  },
  {
    name: "plan-job-explore",
    path: "/plan/job-explore",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/job-explore/index.vue")
  },
  {
    name: "plan-login",
    path: "/plan/login",
    meta: loginxo3gHlClREMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/login.vue")
  },
  {
    name: "plan-onboarding",
    path: "/plan/onboarding",
    meta: indexyKxozoZ6fMMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/onboarding/index.vue")
  },
  {
    name: "plan-portfolio",
    path: "/plan/portfolio",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/portfolio/index.vue")
  },
  {
    name: "plan-summary",
    path: "/plan/summary",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/summary/index.vue")
  },
  {
    name: "plan-terms-condition",
    path: "/plan/terms-condition",
    meta: terms_45condition8ZgsslqJMOMeta || {},
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/terms-condition.vue")
  },
  {
    name: "plan-verify-email",
    path: "/plan/verify-email",
    component: () => import("/var/www/apps/phoenix/nuxt/pages/plan/verify-email/index.vue")
  },
  {
    name: component_45stubARdVsNdG3GMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubARdVsNdG3G
  },
  {
    name: component_45stubARdVsNdG3GMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubARdVsNdG3G
  }
]