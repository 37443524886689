<script setup lang="ts">
import type { NuxtError } from '#app';

interface Props {
  error: NuxtError;
}
const props = defineProps<Props>();

const { isLoggedIn, isGhostStudent } = useAuth();
const { isManageDomain } = useDomainConfig();

const layout = computed(() => {
  if (isLoggedIn.value && isManageDomain) {
    return resolveComponent('LayoutManage');
  }

  if (!isLoggedIn.value || isGhostStudent.value) {
    return resolveComponent('LayoutDefault');
  }

  return resolveComponent('LayoutPlan');
});

const exitErrorScreen = async () => {
  await clearError({ redirect: isLoggedIn.value ? '/dashboard' : '/' });
};

const additionalContextMessage = computed<string | undefined>(() => {
  if (typeof props.error.data === 'string') {
    try {
      const parsedData = JSON.parse(props.error.data);
      return parsedData?.additionalContextMessage || undefined;
    } catch (_) {
      //
    }
  }
  return undefined;
});
</script>

<template>
  <component :is="layout">
    <UICenteredContainer>
      <div class="flex flex-col items-center justify-center" :data-testid="`error-header-${error.statusCode}`">
        <UIHeading class="mb-5" size="h1" styleSize="h3">{{ error.message }}</UIHeading>
        <UIHeading v-if="additionalContextMessage" class="mb-5 mt-3" customClasses="font-normal" size="h2" styleSize="h6">
          {{ additionalContextMessage }}
        </UIHeading>
        <UIButton :text="isLoggedIn ? $t('general.nav.dashboard') : $t('general.nav.home')" @click="exitErrorScreen" />
      </div>
    </UICenteredContainer>
  </component>
</template>
