<script lang="ts" setup>
import type { SystemTheme } from '~ui/types/system';

interface Props {
  labelLeft: string;
  labelRight?: string | null;
  theme?: SystemTheme;
}

const props = withDefaults(defineProps<Props>(), {
  theme: 'light',
  labelRight: null,
});
const value = defineModel<boolean>({ required: true });

const textColor = computed(() => {
  return props.theme === 'dark' ? 'text-white' : 'text-primary-500';
});

const switchStateBackgroundClasses = computed(() => {
  return twMerge(
    cva('w-[64px] h-[32px] transition-transform duration-[700ms] p-1 flex items-center rounded-3xl  border-2 border-primary-500 cursor-pointer', {
      variants: {
        value: {
          true: 'bg-white',
          false: 'bg-grey-100',
        },
      },
    })({
      value: value.value,
    }),
  );
});

const switchStateHandleClasses = computed(() => {
  return twMerge(
    cva('h-[26px] w-[26px] bg-secondary-500 rounded-full transition-all', {
      variants: {
        value: {
          true: 'translate-x-full bg-secondary-500',
          false: 'translate-x-0 bg-grey-500',
        },
      },
    })({
      value: value.value,
    }),
  );
});

const updateValue = () => {
  value.value = !value.value;
};

const handleKeydown = (event: KeyboardEvent): void => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();
    updateValue();
  }
};
</script>

<template>
  <div :aria-checked="value" class="flex items-center gap-4 w-fit" role="switch" tabindex="0" @keydown="handleKeydown">
    <span :class="[textColor]">{{ labelLeft }}</span>
    <span :class="switchStateBackgroundClasses" type="button" @click="updateValue">
      <span :class="switchStateHandleClasses" />
    </span>
    <span v-if="labelRight" :class="[textColor]">{{ labelRight }}</span>
  </div>
</template>
