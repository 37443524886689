/* eslint-disable no-console */
import type { RuntimeConfig } from 'nuxt/schema';
import type { FeatureFlagsProvider, UserContext, Variant, AnalyticsCallback } from '~/types/featureFlagsProvider';
import { UnleashClient } from '@unleash/proxy-client-vue';

interface UnleashImpressionEvent {
  eventType: 'isEnabled' | 'getVariant';
  enabled: boolean;
  featureName: string;
  variant?: string;
}

export default class ClientSideProvider implements FeatureFlagsProvider {
  private client: UnleashClient;
  private userContext?: UserContext;

  constructor(runtimeConfig: RuntimeConfig) {
    this.client = new UnleashClient({
      url: `${runtimeConfig.public.unleashFlagsUrl}/frontend`,
      clientKey: runtimeConfig.public.unleashFlagsClientToken,
      appName: runtimeConfig.public.appName,
      refreshInterval: 30,
    });
  }

  async init(): Promise<void> {
    // throw new Error('TESTING UNLEASH FAILURE ON ONLY CLIENT');
    return await new Promise((resolve, reject) => {
      this.client.on('ready', () => {
        this.attachImpressionsTracker((analyticsData) => {
          useAnalyticsService().track(EVENTS.FEATURE_FLAG_IMPRESSION, analyticsData);
        });
        resolve();
      });
      this.client.on('error', (error: unknown) => {
        console.error('unleash:on-error', error);
        this.destroy();
        reject(error);
      });
      void this.client.start();
    });
  }

  async getFlag(flagName: string, userContext: UserContext): Promise<boolean> {
    await this.syncUserContext(userContext);
    return this.client.isEnabled(flagName);
  }

  async getVariant(flagName: string, userContext: UserContext): Promise<Variant> {
    await this.syncUserContext(userContext);
    return this.client.getVariant(flagName);
  }

  private attachImpressionsTracker(analyticsCallback: AnalyticsCallback): void {
    this.client.on('impression', (e: UnleashImpressionEvent) =>
      analyticsCallback({
        featureFlagType: e.eventType === 'isEnabled' ? 'flag' : 'variant',
        featureFlagName: e.featureName,
        featureFlagEnabled: e.enabled,
        featureFlagVariant: e.variant,
      }),
    );
  }

  private async syncUserContext(userContext: UserContext): Promise<void> {
    if (!userContext || JSON.stringify(userContext) !== JSON.stringify(this.userContext)) {
      this.userContext = userContext;
      await this.client.updateContext(this.userContext);
    }
  }

  destroy(): void {
    this.client.stop();
  }
}
