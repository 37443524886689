import routerOptions0 from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_nfp5cyka32hbdbal6zysdhgkxy/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/apps/phoenix/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}