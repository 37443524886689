<script lang="ts" setup>
import { NO_OP } from '~/graphql/documents/noop';

const { expiry, isLoggedIn } = storeToRefs(useAuthStore());
const { logout } = useAuth();

const FIVE_MINUTES = 5 * 60 * 1000;

const showTimeoutModal = ref<boolean>(false);
const countdown = ref<number>(0);

const formattedCountdown = computed(() => {
  const countdownSec = Math.floor(countdown.value / 1000);
  const minutes = Math.floor(countdownSec / 60);
  const seconds = countdownSec % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
});

const sessionStayLoggedIn = async () => {
  const { $urqlClient } = useNuxtApp();
  await $urqlClient.query(NO_OP, {}, { requestPolicy: 'network-only' });
  showTimeoutModal.value = false;
};

const sessionLogout = async () => {
  await logout();
  showTimeoutModal.value = false;
};

let checkTimeoutInterval: NodeJS.Timeout;

onMounted(() => {
  checkTimeoutInterval = setInterval(() => {
    countdown.value = expiry.value - Date.now();

    if (isLoggedIn.value && expiry.value < Date.now() + FIVE_MINUTES) {
      showTimeoutModal.value = true;

      if (expiry.value < Date.now()) {
        showTimeoutModal.value = false;
        void logout();
      }
    }
  }, 1000);
});

onUnmounted(() => {
  clearInterval(checkTimeoutInterval);
});
</script>

<template>
  <div>
    <UIModal v-if="showTimeoutModal" hideCloseButton>
      <p class="my-5">{{ $t('general.sessionTimeout.dueToInactivity', { countdown: formattedCountdown }) }}</p>
      <div class="flex justify-end">
        <UIButton variant="primary" class="me-3" :text="$t('general.sessionTimeout.keepAlive')" @click="sessionStayLoggedIn()" />
        <UIButton variant="outlined" :text="$t('general.sessionTimeout.logout')" @click="sessionLogout()" />
      </div>
    </UIModal>
  </div>
</template>
