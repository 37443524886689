<script lang="ts" setup></script>

<template>
  <div>
    <UIHeading :htmlText="$t('general.landing.forgeYourPathway')" class="title text-center py-8 font-thin" size="h2" styleSize="h2" />
    <div
      class="pathways px-4 h-[530px] sm:h-[650px] bg-[url('/images/landing/pathways.webp')] [background-position:92%] sm:bg-right lg:bg-bottom bg-cover"
    />
  </div>
</template>
