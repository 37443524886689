<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core';
import StarBackground from '~/components/VisionBoard/StarBackground.vue';

const { t } = useI18n();

definePageMeta({
  authType: [AUTH_ROUTE_TYPE_PLAN_GUEST, AUTH_ROUTE_TYPE_GHOST],
  name: 'plan-index',
});

useHead({
  title: t('general.plan.landingPageTitle'),
  meta: [
    {
      name: 'title',
      content: t('general.plan.landingPageTitle'),
    },
    {
      name: 'keywords',
      content: t('general.plan.keywords'),
    },
  ],
});

const analytics = useAnalyticsService();
onMounted(() => {
  analytics.track(EVENTS.LANDING_PAGE_VIEWED);
});

/* change logo depending on mobile or desktop */
const headerEl = ref<HTMLElement | null>(null);

const { $gsap } = useNuxtApp();
const backToTop = () => {
  $gsap.to(window, { duration: 2, scrollTo: 0 });
};

const { animationsEnabled } = storeToRefs(useAccessibilityStore());

const visibleSections = ref(['stopGuessing']);

const stopGuessing = ref<HTMLElement | null>(null);
useIntersectionObserver(stopGuessing, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('getStarted')) {
    visibleSections.value.push('getStarted');
  }
});

const getStarted = ref<HTMLElement | null>(null);
useIntersectionObserver(getStarted, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('careerCoach')) {
    visibleSections.value.push('careerCoach');
  }
});

const careerCoach = ref<HTMLElement | null>(null);
useIntersectionObserver(careerCoach, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('pathways')) {
    visibleSections.value.push('pathways');
  }
});

const pathways = ref<HTMLElement | null>(null);
useIntersectionObserver(pathways, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('madeForYou')) {
    visibleSections.value.push('madeForYou');
  }
});

const madeForYou = ref<HTMLElement | null>(null);
useIntersectionObserver(madeForYou, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('footer')) {
    visibleSections.value.push('footer');
  }
});

const show = (item: string) => {
  return visibleSections.value.includes(item);
};

const goToFirstNameStep = async () => {
  const toOnboardingAssistantCookie = useCookie('toOnboardingAssistant');
  toOnboardingAssistantCookie.value = 'redirect';
  await navigateTo('/onboarding');
};
</script>

<template>
  <div id="landing-page" class="overflow-x-hidden overflow-y-auto">
    <div>
      <div class="bg-[url('/images/onboardingLandingPageBg.webp')] bg-cover bg-center">
        <StarBackground key="home-page" :hasBackground="false">
          <header ref="headerEl" class="absolute inset-x-0 container flex justify-between py-6 px-4 mx-auto">
            <CoreClusterLogo key="lockupPrimaryDarkHome" variant="lockupPrimaryDark" />
            <UIButton class="text-white underline" text="Log in" to="/login" variant="no-style" />
          </header>
          <div
            class="bg-[url('/images/onboardingLandingPageLandscape.svg')] bg-[length:150%_auto] md:bg-contain bg-no-repeat bg-bottom h-screen py-16 flex justify-center px-4"
          >
            <OnboardingStart v-model="animationsEnabled">
              <template #cta>
                <UIButton :text="$t('activities.onboarding.getStarted')" data-testid="landing-onboarding-cta" size="lg" @click="goToFirstNameStep" />
              </template>
            </OnboardingStart>
          </div>
        </StarBackground>
      </div>
    </div>
    <div ref="stopGuessing">
      <PlanLandingStopGuessing v-if="show('stopGuessing')" />
    </div>
    <div ref="getStarted">
      <PlanLandingGetStarted v-if="show('getStarted')" />
    </div>
    <div ref="careerCoach">
      <PlanLandingCareerCoach v-if="show('careerCoach')" />
    </div>
    <div ref="pathways">
      <PlanLandingPathways v-if="show('pathways')" />
    </div>
    <div ref="madeForYou">
      <PlanLandingMadeForYou v-if="show('madeForYou')" />
    </div>
    <div class="pb-12 text-center">
      <UIButton :text="$t('general.landing.backToTop')" variant="no-style" @click="backToTop" />
    </div>
    <div ref="footer">
      <LazyUIFooter v-if="show('footer')" />
    </div>
  </div>
</template>
