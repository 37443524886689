import type { FocusTrap } from 'focus-trap';
import { createFocusTrap } from 'focus-trap';

export const useFocusTrap = (options = {}) => {
  const elementRef = ref<HTMLElement | null>(null);
  let focusTrap: FocusTrap | null = null;

  const activate = () => {
    if (elementRef.value) {
      focusTrap = createFocusTrap(elementRef.value, {
        escapeDeactivates: false,
        allowOutsideClick: true,
        ...options,
      });
      focusTrap.activate();
    }
  };

  const deactivate = () => {
    if (focusTrap) {
      focusTrap.deactivate();
    }
  };

  onMounted(() => {
    activate();
  });

  onBeforeUnmount(() => {
    deactivate();
  });

  return {
    elementRef,
    activate,
    deactivate,
  };
};
