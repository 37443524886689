export interface AnalyticsProvider {
  identify: (guid: string | undefined) => void;
  getSubscribedEvents: () => string[];
  track: (eventName: string, eventData?: object) => void;
  init: () => Promise<void> | void;
}

export enum SiteType {
  PLAN = 'plan',
  MANAGE = 'manage',
}
