<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';
import type { NavItem } from '~ui/types/navigation';

interface Props {
  navItems: NavItem[];
  footerItems?: NavItem[];
}

withDefaults(defineProps<Props>(), {
  footerItems: () => [],
});

const isSidebarOpen = defineModel<boolean>('open', { required: true });

const widthClasses = computed<string>(() => {
  return twMerge(
    cva('shrink-0', {
      variants: {
        isSidebarOpen: {
          true: 'w-[200px]',
          false: 'w-[72px]',
        },
      },
    })({
      isSidebarOpen: isSidebarOpen.value,
    }),
  );
});

const toggleNav = (): void => {
  isSidebarOpen.value = !isSidebarOpen.value;
};
</script>

<template>
  <nav class="z-50 items-center min-h-screen transition-all duration-700 shadow-sm bg-primary-500 shadow-primary-500 relative" :class="widthClasses">
    <div class="flex flex-col justify-center space-y-2">
      <div class="hover:bg-primary-800 h-[72px] w-full text-white">
        <button class="w-full h-full" :aria-label="isSidebarOpen ? 'close navigation' : 'open navigation'" @click="toggleNav">
          <UIAssetIcon :path="isSidebarOpen ? 'cross' : 'hamburger'" size="md" />
        </button>
      </div>
      <div class="h-[72px] w-full">
        <div class="flex items-center justify-center w-full h-full">
          <slot name="logo" />
        </div>
      </div>
      <ul>
        <UINavigationSideLink
          v-for="(link, i) in navItems"
          :key="`link-${i}`"
          :label="link.label"
          :iconPath="link.iconPath"
          :to="link.to"
          :isOpen="isSidebarOpen"
          :activeRoutes="link.activeRoutes"
          :onClick="link.onClick"
          :badgeCount="link.badgeCount"
        />
      </ul>
      <ul v-if="footerItems.length" class="absolute bottom-0 w-full left-0 text-white">
        <UINavigationSideLink
          v-for="(link, i) in footerItems"
          :key="`link-${i}`"
          :label="link.label"
          :iconPath="link.iconPath"
          :to="link.to"
          :isOpen="isSidebarOpen"
          :activeRoutes="link.activeRoutes"
          :onClick="link.onClick"
        />
      </ul>
    </div>
  </nav>
</template>
