import { required, email, maxLength, helpers, url } from '@vuelidate/validators';

export const useValidation = () => {
  const { t } = useI18n();
  const configStore = useConfigStore();
  const country = configStore.publicConfig?.country;
  if (!country) {
    throw new Error('configStore.publicConfig.country is not defined');
  }

  const MAX_STRING_LENGTH = 255;

  // Define regex patterns for different countries
  const postcodeRegexByCountry: Record<string, RegExp> = {
    USA: /^[0-9]{5}(?:-[0-9]{4})?$/,
    AUS: /^\d{4}$/,
  };

  // Get the regex pattern for the current country
  const postcodeRegex = postcodeRegexByCountry[country.code];

  // Validation rule for not undefined
  const notUndefinedRule = (errorMessage: string) => helpers.withMessage(errorMessage, (v) => v !== undefined);

  // Validation rule for HTTPS URLs
  const httpsUrl = helpers.withMessage(t('validation.httpsUrl'), (value: string) => {
    if (!value) {
      return true; // Don't validate if empty, use `required` for mandatory check
    }
    return value.startsWith('https://');
  });

  // Validation rule for postcode
  const postcode = helpers.withMessage(t('validation.invalidPostcode'), (value: string) => {
    if (!value) {
      return true; // Don't validate if empty, use `required` for mandatory check
    }
    return postcodeRegex.test(value); // Validate against the selected regex
  });

  return {
    helpers,
    required: helpers.withMessage(t('validation.required', { attribute: 'This' }), required),
    email: helpers.withMessage(t('validation.email'), email),
    maxLength,
    maxStringLength: maxLength(MAX_STRING_LENGTH),
    url: helpers.withMessage(t('validation.invalidUrl'), url),
    httpsUrl,
    postcode,
    dateLte: (maxDateVal: string, fieldName: string) =>
      helpers.withParams(
        { type: 'dateLte', value: maxDateVal },
        helpers.withMessage(t('validation.mustBeBefore', { fieldName }), (value: string) => {
          if (!maxDateVal) {
            return true;
          }
          return new Date(maxDateVal) >= new Date(value);
        }),
      ),
    notUndefinedRule,
  };
};
