<script lang="ts" setup>
import LayoutPrivate from './LayoutPrivate.vue';
import { useAuthUserActivityGroups } from '~/composables/activity';

interface Props {
  hasMargins?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasMargins: true,
});

const { t } = useI18n();
const { logout } = useAuth();
const { currentActivityGroupIsVisionBoard } = useAuthUserActivityGroups();

const navItems = computed(() => {
  const baseItems = [
    {
      to: '/dashboard',
      label: t('general.platform.dashboardTitle'),
      iconPath: 'dashboard',
      activeRoutes: ['plan-dashboard'],
    },
  ];

  if (currentActivityGroupIsVisionBoard.value) {
    return baseItems;
  }

  return [
    ...baseItems,
    {
      to: '/portfolio',
      label: 'Portfolio',
      iconPath: 'user-square',
      activeRoutes: ['plan-portfolio'],
    },
  ];
});

const footerItems = [
  {
    to: '/account',
    label: t('general.nav.profile'),
    iconPath: 'cog',
    activeRoutes: ['plan-account'],
  },
  {
    label: t('general.nav.logout'),
    iconPath: 'logout',
    activeRoutes: [],
    onClick: logout,
  },
];

const { animationsEnabled } = storeToRefs(useAccessibilityStore());
</script>

<template>
  <LayoutPrivate :class="{ 'disable-animations': !animationsEnabled }" :footerItems="footerItems" :hasMargins="hasMargins" :navItems="navItems">
    <template #default>
      <slot />
    </template>
    <template #extras>
      <slot name="extras" />
    </template>
  </LayoutPrivate>
</template>
